import React, { useEffect, useState, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Typography,
  Tab,
  Tabs,
  Stack,
  Card,
  Switch,
  FormGroup,
  FormControlLabel,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  CircularProgress,
  Skeleton,
  Box,
  ImageList,
} from '@mui/material';
import DeselectIcon from '@mui/icons-material/Deselect';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import _ from 'lodash';
import { AlertsContext } from '../shared/alerts/Alerts.context';

import StickersService from './Stickers.service';
import StickerList from './StickerList';
import Sticker from './Sticker';
import StickersCreateModal from './StickerCreateModal';
import styleColors from '../colors.styles';
import StickersDetail from './StickersDetail';
import StickerCategoryList from './StickerCategoryList';
import LoggingService from '../shared/Logging.api';
import SortableList from './SortableList';
import SortableItem from './SortableItem';

const Stickers = () => {
  const smallScreen = !useMediaQuery('(min-width:900px)');
  const { showAlert } = useContext(AlertsContext);

  // stickers is the list of stickers that will be displayed
  const [stickers, setStickers] = useState([]);
  // allStickers is the list of all stickers including inactive or those on other tabs
  const [allStickers, setAllStickers] = useState([]);
  // selectedStickers is the list of stickers that the user has selected
  const [selectedStickers, setSelectedStickers] = useState([]);

  // allCategories is the list of all categories including inactive or those with no stickers
  const [allCategories, setAllCategories] = useState([]);
  // activeCategories is the list of categories that are currently enabled
  const [activeCategories, setActiveCategories] = useState([]);
  // selectedCategoryID is the ID of the category that the user has selected (by changing tabs)
  const [selectedCategoryID, setSelectedCategoryID] = useState('');

  const [showInactive, setShowInactive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [stickersLoading, setStickersLoading] = useState(true);

  const [sorting, setSorting] = useState(false);
  const [sortedStickers, setSortedStickers] = useState([]);
  const [savingSort, setSavingSort] = useState(false);

  useEffect(() => {
    // Fetch all stickers
    fetchStickersAndCategories();
  }, []);

  // when the user changes tabs or the inactive toggle, refilter the stickers
  useEffect(() => {
    setSelectedStickers([]);
    filterStickers();
  }, [selectedCategoryID, showInactive, allStickers]);

  const fetchStickersAndCategories = () => {
    StickersService.getStickers(false)
      .then((response) => {
        // Format stickers to include an active flag
        const formattedStickers = [];
        if (response.data) {
          _.forEach(response.data, (sticker) => {
            const now = new Date();
            const stickerActive =
              new Date(sticker.start_date) <= now &&
              (new Date(sticker.end_date) >= now || !sticker.end_date);
            formattedStickers.push({
              ...sticker,
              created_at: new Date(sticker.created_at),
              active: stickerActive,
            });
          });
        }

        setAllStickers(formattedStickers);
        filterStickers();

        // Fetch all categories
        StickersService.getStickerCategories(false)
          .then((categories) => {
            setAllCategories(categories);
            const active = _.orderBy(
              _.filter(categories, ['enabled', true]),
              ['order_index'],
              ['desc']
            );
            setActiveCategories(active);
            setSelectedCategoryID(active[0].id);
            setStickersLoading(false);
          })
          .catch((error) => {
            LoggingService.error('Error fetching sticker categories', error);
            showAlert('Error fetching sticker categories', 'error');
          });
      })
      .catch((error) => {
        LoggingService.error('Error fetching stickers', error);
        showAlert('Error fetching stickers', 'error');
      });
  };

  const endStickers = async (stickerArray) => {
    const stickerIds = stickerArray.map((sticker) => sticker.id);
    try {
      await StickersService.endStickers(stickerIds);
    } catch (error) {
      LoggingService.error('Error ending stickers', error);
      showAlert('Ending stickers', 'error');
    }

    fetchStickersAndCategories();
  };

  // Re-filter the displayed stickers based on selected category and showInactive
  const filterStickers = () => {
    const selectedCategoryStickers = _.orderBy(
      _.filter(
        allStickers,
        (sticker) => sticker.category_id === selectedCategoryID
      ),
      ['active', 'order_index', 'created_at'],
      ['desc', 'desc', 'desc']
    );

    const activeStickers = _.filter(
      selectedCategoryStickers,
      (sticker) => sticker.active
    );
    setSortedStickers(activeStickers);
    if (!showInactive) {
      setStickers(activeStickers);
    } else {
      setStickers(selectedCategoryStickers);
    }
  };

  // function to pass down to the StickerList and handle when a sticker is clicked
  const handleSelectSticker = (sticker) => {
    if (_.find(selectedStickers, sticker)) {
      setSelectedStickers(
        _.filter(
          selectedStickers,
          (selectedSticker) => selectedSticker.id !== sticker.id
        )
      );
    } else {
      setSelectedStickers([...selectedStickers, sticker]);
    }
  };

  const handleToggle = () => {
    setShowInactive(!showInactive);
  };

  const styles = {
    page: smallScreen
      ? { width: '90%' }
      : { margin: '0 auto 100 auto', width: '90vw' },
    stickerSheetContainer: {
      paddingBottom: smallScreen ? 0 : '30px',
      width: smallScreen ? '100%' : '50%',
      minWidth: '300px',
    },
    stickerSheet: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      gap: '20px',
    },
    categoryTitle: {
      color: 'white',
    },
    typography: {
      paddingTop: '20px',
    },
    stackContainer: {
      width: '100%',
      margin: 0,
    },
    stackItem: {
      paddingTop: '110px',
      minWidth: '30%',
    },
    card: {
      backgroundColor: styleColors.base.barely_blue,
    },
    createFormTypography: {
      variant: 'h6',
      align: 'left',
    },
    stickerTabs: {
      backgroundColor: 'white',
      marginBottom: 0,
    },
    tabIndicator: {
      backgroundColor: styleColors.base.active,
      height: '10px',
    },
    categoryImage: {
      width: '30px',
      height: '30px',
      objectFit: 'contain',
    },
    deselectAllTab: {
      marginLeft: 'auto',
      textAlign: 'right',
      color:
        selectedStickers.length > 0
          ? styleColors.base.walmart_blue
          : styleColors.base.medium_gray,
    },
    FAB: {
      backgroundColor: styleColors.base.dark_light_blue,
      color: 'white',
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      '&:hover': {
        backgroundColor: styleColors.base.active,
        color: 'black',
      },
    },
    createButton: {
      backgroundColor: styleColors.base.walmart_blue,
      height: '50px',
      color: 'white',
      margin: '30px auto',
      width: '100%',
    },
    previewSticker: {
      width: '100%',
      height: '100%',
      maxHeight: '110px',
      minHeight: '70px',
      minWidth: '70px',
      maxWidth: '110px',
      objectFit: 'contain',
      backgroundColor: 'black',
      padding: '5px',
      borderRadius: '5px',
      margin: '20px auto',
      position: 'relative',
    },
    alert: {
      position: 'fixed',
      top: 20,
      right: 20,
      width: '20vw',
      zIndex: '1000',
    },
    sortingButton: {
      backgroundColor: savingSort
        ? styleColors.base.light_gray
        : styleColors.base.walmart_blue,
      color: 'white',
    },
    imageList: {
      width: '100%',
      minWidth: '550px',
      height: '95%',
      maxHeight: `${sortedStickers.length * 40}px`,
      backgroundColor: 'black',
      padding: '20px',
      minHeight: '60vh',
      marginTop: 0,
      borderRadius: '3px',
      color: 'white',
    },
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Are you sure you want to end these stickers?</DialogTitle>
        <DialogContent>
          <Grid
            style={{ gridTemplateColumns: '1fr 1fr 1fr', display: 'grid' }}
            spacing={3}
          >
            {selectedStickers.map((sticker) => (
              <div style={styles.previewSticker}>
                <Sticker key={sticker.id} sticker={sticker} />
              </div>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              endStickers(selectedStickers);
              setConfirmDialogOpen(false);
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleSaveSorting = () => {
    setSavingSort(true);
    const updatedSortedStickers = sortedStickers.map((sticker, index) => ({
      ...sticker,
      order_index: sortedStickers.length - index, // reverse the order so the first sticker is the highest
      category: sticker.category_id, // change category to category_id to send the correct attribute to the server
    }));
    const updatedInactiveStickers = _.filter(
      allStickers,
      (sticker) => !sticker.active && sticker.category_id === selectedCategoryID
    ).map((inactiveSticker, index) => {
      return {
        ...inactiveSticker,
        order_index: updatedSortedStickers.length + index + 1,
        category: inactiveSticker.category_id,
      };
    });

    const updatedStickers = [
      ...updatedSortedStickers,
      ...updatedInactiveStickers,
    ];

    StickersService.updateStickers(updatedStickers)
      .then((response) => {
        setAllStickers((prev) => {
          const categoryMap = new Map(
            allStickers
              .filter((sticker) => sticker.category_id === selectedCategoryID)
              .map((sticker) => [sticker.id, sticker.category])
          );
          const updatedResponse = response.map((sticker) => ({
            ...sticker,
            category: categoryMap.get(sticker.id),
          }));

          const unmodifiedStickers = prev.filter(
            (sticker) => !updatedStickers.map((s) => s.id).includes(sticker.id)
          );
          return [...updatedResponse, ...unmodifiedStickers];
        });
      })
      .catch((error) => {
        LoggingService.error('Error updating stickers', error);
        showAlert('Error updating stickers', 'error');
      })
      .finally(() => {
        setSavingSort(false);
        setSorting(false);
      });
  };

  const getStickersIndexByCategory = (category) => {
    const stickersByCategory = allStickers.filter(
      (sticker) => sticker.category_id === category
    );
    const maxOrderIndex = stickersByCategory.reduce((max, sticker) => {
      if (sticker.order_index > max) {
        return sticker.order_index;
      }
      return max;
    }, 0);
    return maxOrderIndex + 1;
  };

  const renderSticker = (sticker) => {
    return <Sticker sticker={sticker} status="active" />;
  };

  const renderSortableStickers = (sortableStickers) => {
    return (
      <ImageList sx={styles.imageList} cols={5} rowHeight={100} gap={40}>
        {sortableStickers.map((item) => (
          <SortableItem
            key={item.order_index}
            itemId={item.id}
            renderFunc={renderSticker(item)}
          />
        ))}
      </ImageList>
    );
  };

  return (
    <div style={{ ...styles.page, position: 'relative' }}>
      <Typography variant="h4" align="left" style={styles.typography}>
        Stickers
      </Typography>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
        style={styles.stackContainer}
        spacing={{ xs: 0, md: 4 }}
      >
        <div style={styles.stackItem}>
          <Stack direction="column" spacing={3}>
            <StickersDetail
              selectedStickers={selectedStickers}
              setSelectedStickers={setSelectedStickers}
              smallScreen={smallScreen}
              endStickers={() => setConfirmDialogOpen(true)}
              setModalOpen={setModalOpen}
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<AddReactionIcon />}
              onClick={() => {
                setSelectedStickers([]);
                setModalOpen(true);
              }}
              style={styles.createButton}
            >
              Create sticker
            </Button>
            {modalOpen && (
              <StickersCreateModal
                categories={activeCategories}
                closeModal={() => {
                  setModalOpen(false);
                }}
                refetchStickers={fetchStickersAndCategories}
                selectedStickers={
                  selectedStickers.length > 0 ? selectedStickers : []
                }
                getStickersIndexByCategory={getStickersIndexByCategory}
              />
            )}

            {stickersLoading ? (
              <Skeleton variant="rectangular" height="20vh" />
            ) : (
              <Card style={{ ...styles.card, paddingBottom: '150px' }}>
                <StickerCategoryList
                  allCategories={allCategories}
                  activeCategories={activeCategories}
                  refetch={fetchStickersAndCategories}
                  stickers={allStickers}
                  endStickers={endStickers}
                />
              </Card>
            )}
          </Stack>
        </div>
        {stickersLoading ? (
          <div
            style={{
              width: '100%',
              backgroundColor: styleColors.base.almost_white_blue,
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              style={{ margin: '20vh auto' }}
            />
            <CircularProgress
              style={{
                margin: '20vh auto',
                position: 'absolute',
                top: 40,
                left: '50%',
              }}
            />
          </div>
        ) : (
          <div style={styles.stickerSheetContainer}>
            {sorting ? (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" justifyContent="end" gap={2}>
                  <Button
                    onClick={() => {
                      setSorting(false);
                      filterStickers();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={styles.sortingButton}
                    onClick={handleSaveSorting}
                  >
                    Save
                  </Button>
                </Box>
                <p style={{ margin: 0 }}>
                  The changes you make here will be reflected in the MLS app
                  after clicking &quot;Save&quot;.
                </p>
                <SortableList
                  items={sortedStickers}
                  setItems={setSortedStickers}
                  renderFunc={renderSortableStickers(sortedStickers)}
                />
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" justifyContent="end" gap={2}>
                  <Button
                    style={styles.sortingButton}
                    onClick={() => setSorting(true)}
                  >
                    Reorder Stickers
                  </Button>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch checked={showInactive} onClick={handleToggle} />
                      }
                      label="Show inactive stickers"
                    />
                  </FormGroup>
                </Box>
                <>
                  <Tabs
                    style={styles.stickerTabs}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                      style: styles.tabIndicator,
                    }}
                    value={selectedCategoryID}
                  >
                    {activeCategories.map((category) => (
                      <Tab
                        key={category.id}
                        label={category.name}
                        value={category.id}
                        icon={
                          <img
                            src={category.image}
                            alt={category.name}
                            style={styles.categoryImage}
                          />
                        }
                        onClick={() => setSelectedCategoryID(category.id)}
                      />
                    ))}
                    <Tab
                      label="Deselect All"
                      value={null}
                      onClick={() => {
                        setSelectedStickers([]);
                      }}
                      style={styles.deselectAllTab}
                    />
                  </Tabs>
                  <StickerList
                    stickers={stickers}
                    handleSelect={handleSelectSticker}
                    selectedStickers={selectedStickers}
                  />
                </>
              </Box>
            )}
          </div>
        )}
      </Stack>
      {selectedStickers.length > 0 && (
        <Fab
          variant="extended"
          size="small"
          sx={styles.FAB}
          onClick={() => {
            setSelectedStickers([]);
          }}
        >
          <DeselectIcon sx={{ mr: 1 }} />
          Deselect ({selectedStickers.length})
        </Fab>
      )}
      {confirmDialogOpen && renderDialog()}
    </div>
  );
};

export default Stickers;
