import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardMedia, Typography } from '@mui/material';

const SortableCategory = ({ category, additionalStyles = {} }) => {
  const styles = {
    category: {
      padding: '10px',
      margin: '10px',
      overflow: 'visible',
      borderRadius: '3px',
      ...additionalStyles,
    },
    cardMedia: {
      width: '50px',
      height: '50px',
      backgroundSize: 'contain',
      margin: '0 auto',
    },
    title: { margin: 'auto', textAlign: 'left' },
  };

  return (
    <Card
      style={{
        ...styles.category,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexDirection="row"
      >
        <Box display="flex" gap={2}>
          <CardMedia
            image={category.image}
            title={category.name}
            style={styles.cardMedia}
          />
          <Typography style={styles.title}>{category.name}</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default SortableCategory;

SortableCategory.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
  }).isRequired,
  additionalStyles: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

SortableCategory.defaultProps = {
  additionalStyles: {},
};
