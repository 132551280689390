import React, { useEffect, useState } from 'react';
import { Box, Tab, Typography } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import {
  TokenType,
  BackgroundType,
  LayoutType,
  DisplayOption,
} from 'powerbi-models';
import './PowerBI.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import powerbiApi from './PowerBI.api';
import SpinningSpark from '../shared/components/SpinningSpark';
import colors from '../colors.styles';

export default function PowerBI() {
  const tabs = {
    snapshot: 'snapshotOverview',
    musicMatch: 'proactiveMusicMatch',
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [selectedTab, setSelectedTab] = useState(tabs.snapshot);

  const styles = {
    tabList: {
      '.MuiTabs-flexContainer': {
        borderBottom: `2px solid ${colors.base.light_gray}`,
      },
      '.MuiTabs-indicator': {
        backgroundColor: colors.base.spark_yellow,
      },
      '.MuiButtonBase-root': {
        textTransform: 'capitalize',
        fontSize: '1rem',
        backgroundColor: colors.base.almost_white_blue,
        borderRadius: '10px 10px 0 0',
      },
      '.Mui-selected': {
        color: `${colors.base.light} !important`,
        backgroundColor: colors.base.walmart_blue,
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    setError(false);

    powerbiApi
      .getPowerBICredentials(selectedTab)
      .then((response) => setCredentials(response.data))
      .catch((err) => {
        console.error(err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [selectedTab]);

  const embedConfig = {
    type: 'report',
    tokenType: TokenType.Embed,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: BackgroundType.Transparent,
      layoutType: LayoutType.Custom,
      customLayout: {
        displayOption: DisplayOption.FitToWidth,
      },
      navContentPaneEnabled: false,
      visualSettings: {
        visualHeaders: [
          {
            settings: {
              visible: false,
            },
            selector: {
              $schema: 'http://powerbi.com/product/schema#visualSelector',
              visualName: '7379e14d7e10f32927e6',
            },
          },
        ],
      },
    },
  };

  const reportBox = (tab) => {
    // Only display report on the selected tab
    if (tab !== selectedTab) {
      return null;
    }

    if (loading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <SpinningSpark />
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="subtitle1">
            Failed to load PowerBI report.
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <PowerBIEmbed
          embedConfig={{
            ...embedConfig,
            accessToken: credentials?.embedToken,
            embedUrl: credentials?.url,
          }}
          cssClassName="report-container"
        />
      </Box>
    );
  };

  return (
    <TabContext value={selectedTab}>
      <TabList
        onChange={(_, newValue) => setSelectedTab(newValue)}
        sx={styles.tabList}
      >
        <Tab label="Snapshot Overview" value={tabs.snapshot} />
        <Tab label="Proactive Music Match" value={tabs.musicMatch} />
      </TabList>
      <TabPanel value={tabs.snapshot}>{reportBox(tabs.snapshot)}</TabPanel>
      <TabPanel value={tabs.musicMatch}>{reportBox(tabs.musicMatch)}</TabPanel>
    </TabContext>
  );
}
