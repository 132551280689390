/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  IconButton,
  Stack,
  Box,
} from '@mui/material';
import {
  AudioFile,
  CalendarMonth,
  ChevronLeft,
  ChevronRight,
  Groups,
  HelpOutline,
  PowerSettingsNew,
  Store,
  Task,
  AddReaction,
  BarChart,
  EmojiEvents,
} from '@mui/icons-material';
import { AuthContext } from 'react-oauth2-code-pkce';
import { UserContext } from '../auth/User.context';
import UserService from '../users/Users.api';
import config from '../config';
import logoSmall from '../assets/logo100.png';
import logoLarge from '../assets/MLS_logoX2.png';
import colors from '../colors.styles';
import HelpModal from '../shared/components/HelpModal';
import CustomDrawer from '../shared/components/CustomDrawer';

export default function DrawerMenu() {
  const { TENANT, APP_ENV, VERSION, MENU_ITEMS } = config;
  const { signOut, state } = useContext(UserContext);
  const { logOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const navigate = useNavigate();
  const styles = {
    drawerPaper: {
      minWidth: 92,
      backgroundColor: colors.base.walmart_blue,
    },
    menuItemButton: {
      justifyContent: open ? 'initial' : 'center',
      px: 2.5,
    },
    menuItemText: {
      color: colors.base.light,
    },
    menuItemIcon: {
      justifyContent: 'center',
    },
    menuIcon: {
      color: colors.base.light,
    },
  };

  const menuItems = [
    {
      text: 'Video Review',
      icon: <AudioFile sx={styles.menuIcon} />,
      action: () => navigate('/videoReviewDashboard'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Decision'
      ),
    },
    {
      text: 'Users',
      icon: <Groups sx={styles.menuIcon} />,
      action: () => navigate('/users'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Users'
      ),
    },
    {
      text: 'Social Champs',
      icon: <EmojiEvents sx={styles.menuIcon} />,
      action: () => navigate('/socialChamps'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Users'
      ),
    },
    {
      text: 'Stores',
      icon: <Store sx={styles.menuIcon} />,
      action: () => navigate('/stores'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Stores'
      ),
    },
    {
      text: 'Priorities',
      icon: <Task sx={styles.menuIcon} />,
      action: () => navigate('/priorities'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Priorities'
      ),
    },
    {
      text: 'Stickers',
      icon: <AddReaction sx={styles.menuIcon} />,
      action: () => navigate('/stickers'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Stickers'
      ),
    },
    {
      text: 'Post of the Day',
      icon: <CalendarMonth sx={styles.menuIcon} />,
      action: () => navigate('/potd'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'Manage Post of the Day'
      ),
    },
    {
      text: 'PowerBI',
      icon: <BarChart sx={styles.menuIcon} />,
      action: () => navigate('/powerbi'),
      requiredPermission: state.adminPanelActivePermissions.find(
        (permission) => permission.title === 'View Video Report'
      ),
    },
    {
      text: 'Help',
      icon: <HelpOutline sx={styles.menuIcon} />,
      action: () => setOpenHelpModal(true),
    },
    {
      text: 'Sign Out',
      icon: <PowerSettingsNew sx={styles.menuIcon} />,
      action: handleSignOut,
    },
  ];

  function handleSignOut() {
    // Sign out of the app, first by destroying the session in the backend, then updating the state
    // and finally logging out of pingfed
    UserService.signOutPingFed()
      .then(() => {
        signOut();
        logOut();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleDrawOpenClose() {
    setOpen(!open);
  }

  const filteredMenuItems = menuItems.filter(
    (item) =>
      item.text === 'Sign Out' ||
      item.text === 'Help' ||
      (MENU_ITEMS.includes(item.text) &&
        state.userPermissions.some(
          (permission) => permission.id === item.requiredPermission?.id
        ))
  );

  return (
    <>
      <CustomDrawer
        variant="permanent"
        PaperProps={{
          sx: styles.drawerPaper,
        }}
        open={open}
        sx={{ marginRight: '28px' }}
      >
        <Stack direction="column" sx={{ height: '100%' }}>
          <List>
            <ListItemButton
              onClick={() => {
                navigate('/');
              }}
              sx={{
                '&:hover': { backgroundColor: 'transparent' },
                marginTop: 2,
              }}
              disableRipple
              disableTouchRipple
            >
              <img
                src={open ? logoLarge : logoSmall}
                alt="mls-logo"
                height="60"
              />
            </ListItemButton>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: open ? 'row' : 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '4px',
              }}
            >
              <Typography
                sx={{ ...styles.menuItemText, textTransform: 'uppercase' }}
              >
                {TENANT}
              </Typography>
              <Typography sx={styles.menuItemText}>
                {APP_ENV !== 'prod' && APP_ENV}
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <IconButton onClick={handleDrawOpenClose}>
                {open ? (
                  <ChevronLeft sx={styles.menuIcon} />
                ) : (
                  <ChevronRight sx={styles.menuIcon} />
                )}
              </IconButton>
            </ListItem>
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <List>
            {state &&
              state.userPermissions &&
              filteredMenuItems.map((item) => (
                <ListItemButton
                  key={item.text}
                  onClick={item.action}
                  sx={styles.menuItemButton}
                >
                  <ListItemIcon sx={styles.menuItemIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{ ...styles.menuItemText, opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              ))}
            <ListItem>
              <ListItemText
                sx={{ ...styles.menuItemText, textAlign: 'center' }}
              >
                {open && 'App Version'} {VERSION}
              </ListItemText>
            </ListItem>
          </List>
        </Stack>
      </CustomDrawer>
      <HelpModal open={openHelpModal} setOpen={setOpenHelpModal} />
    </>
  );
}
