/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { CheckCircle } from '@mui/icons-material';
import StringUtils from '../shared/utils/string.utils';
import UserService from './Users.api';
import SpinningSpark from '../shared/components/SpinningSpark';
import LoggingService from '../shared/Logging.api';
import styleColors from '../colors.styles';

export default function UserPermissions({ user, getUser }) {
  const AUTH_ATTRIBUTES = [
    {
      label: 'Region',
      key: 'region',
    },
    {
      label: 'Market',
      key: 'market',
    },
    {
      label: 'Health & Wellness Market',
      key: 'health_market',
    },
    {
      label: 'Store',
      key: 'store',
    },
  ];

  const {
    hasDefaultStore,
    extractDataFromAuthorization,
    extractStoreNumberFromEmail,
    generateAuthorizationString,
  } = StringUtils;

  // set initial state to the user's current authorization from the AUTH_ATTRIBUTES key
  const [textFields, setTextFields] = useState(
    AUTH_ATTRIBUTES.map((attribute) =>
      extractDataFromAuthorization(user.authorization, attribute.key)
    )
  );

  const [activityDashboardRegion, setActivityDashboardRegion] = useState(
    user.activity_dashboard_access || ''
  );
  // check if user.authorization is a string with numbers or *
  const initialStores = extractDataFromAuthorization(
    user.authorization,
    'store'
  );

  function initialRadioGroupValue() {
    if (initialStores[0] === '*') {
      return 'admin';
    }
    if (initialStores[0] === '0') {
      return 'noAccess';
    }
    return 'custom';
  }

  const [RadioValue, setRadioValue] = useState(initialRadioGroupValue());
  const [updateSuccessOpen, setUpdateSuccessOpen] = useState(false);
  const [isStoreAccessLoading, setIsStoreAccessLoading] = useState(false);
  const [isReportAccessLoading, setIsReportAccessLoading] = useState(false);
  const [storeInputHasChanged, setStoreInputHasChanged] = useState(false);
  const [reportInputHasChanged, setReportInputHasChanged] = useState(false);

  function handleClose() {
    setUpdateSuccessOpen(false);
  }

  const userHasDefaultStore = hasDefaultStore(user.email);

  function handleRadioChange(e) {
    setRadioValue(e.target.value);
  }

  async function handleSubmit() {
    setIsStoreAccessLoading(true);
    let updatePermissions = {};

    const authorizationString = generateAuthorizationString(
      AUTH_ATTRIBUTES,
      textFields
    );

    const authStrings = {
      admin: '*',
      custom: authorizationString,
      noAccess: 'store=0',
      revertAccess: `store=${extractStoreNumberFromEmail(user.email)}`,
    };

    // create the updatePermissions object based on the RadioValue
    updatePermissions = {
      authorization: authStrings[RadioValue],
    };

    try {
      await UserService.updateUser(user.platform_id, updatePermissions);
      if (RadioValue === 'noAccess') {
        await UserService.updateDashboardAccess(user.platform_id, '');
      }

      getUser();
      setUpdateSuccessOpen(true);
      // if radioValue is different than custom, set the textFields to empty
      if (RadioValue !== 'custom') {
        setTextFields(AUTH_ATTRIBUTES.map(() => ''));
      }
    } catch (error) {
      LoggingService.error('Error updating permissions', error);
    } finally {
      setStoreInputHasChanged(false);
      setIsStoreAccessLoading(false);
    }
  }

  async function handleSubmitReportAccess() {
    setIsReportAccessLoading(true);
    try {
      await UserService.updateDashboardAccess(
        user.platform_id,
        activityDashboardRegion
      );

      getUser();
      setUpdateSuccessOpen(true);
    } catch (error) {
      LoggingService.error('Error updating permissions', error);
    } finally {
      // wait 1 seconds so the spinner can appear
      setTimeout(() => {
        setIsReportAccessLoading(false);
        setReportInputHasChanged(false);
      }, 1000);
    }
  }

  // function to check if all the values are empty and the radio button is custom to set the Submit button disabled
  function valuesAreEmpty() {
    if (
      RadioValue === 'custom' &&
      textFields.every((value) => value?.trim() === '' || value === undefined)
    ) {
      return true;
    }
    return false;
  }

  const centerAbsolute = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  };

  const style = {
    ...centerAbsolute,
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const TextFieldStyles = {
    marginBottom: 14,
  };

  const ContainerStyle = {
    borderRadius: '5px',
    marginBottom: 14,
    padding: 10,
    backgroundColor: styleColors.base.very_light_gray,
  };

  const PermissionTitleStyle = {
    fontWeight: 'bold',
    paddingBottom: 14,
  };

  return (
    <Grid
      container
      direction="column"
      xs={5}
      style={{
        minHeight: '100vh',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <Typography variant="h4" style={{ textAlign: 'center' }}>
        User Permissions
      </Typography>
      <Divider />
      <FormControl
        disabled={isStoreAccessLoading}
        style={{ marginBottom: 40, marginTop: 10 }}
      >
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={RadioValue}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
          <FormControlLabel
            value="noAccess"
            control={<Radio />}
            label="No Access"
          />
          <FormControlLabel
            disabled={!userHasDefaultStore}
            value="revertAccess"
            control={<Radio />}
            label="Revert Access"
          />
        </RadioGroup>
      </FormControl>
      <Grid style={ContainerStyle} container direction="column">
        {RadioValue === 'custom' && (
          <>
            <Typography variant="body" style={PermissionTitleStyle}>
              Where can the user post?
            </Typography>
            {AUTH_ATTRIBUTES.map((attribute, index) => (
              <TextField
                onChange={(e) => {
                  const { value } = e.target;
                  setTextFields((prevState) => {
                    const newState = [...prevState];
                    newState[index] = value;
                    setStoreInputHasChanged(true);
                    return newState;
                  });
                }}
                disabled={isStoreAccessLoading}
                key={attribute.key}
                label={attribute.label}
                value={textFields[index] || ''}
                style={TextFieldStyles}
                InputProps={{
                  style: { backgroundColor: 'white' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setTextFields((prevState) => {
                            const newState = [...prevState];
                            newState[index] = undefined;
                            return newState;
                          });
                          setStoreInputHasChanged(true);
                        }}
                      >
                        Clear
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            ))}
          </>
        )}
        {isStoreAccessLoading ? (
          <Box display="flex" justifyContent="center">
            <SpinningSpark isSpinning height="40" width="40" marginTop="0" />
          </Box>
        ) : (
          <Button
            disabled={!storeInputHasChanged || valuesAreEmpty()}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography variant="button" style={{ marginLeft: 10 }}>
              Submit changes
            </Typography>
          </Button>
        )}
      </Grid>
      {RadioValue !== 'noAccess' && (
        <>
          <Grid style={ContainerStyle} container direction="column">
            <Typography variant="body" style={PermissionTitleStyle}>
              What report can the user view?
            </Typography>
            <TextField
              onChange={(e) => {
                setReportInputHasChanged(true);
                setActivityDashboardRegion(e.target.value);
              }}
              disabled={isReportAccessLoading}
              key="activityDashboardRegion"
              label="Activity Dashboard Region"
              value={activityDashboardRegion}
              style={TextFieldStyles}
              InputProps={{
                style: { backgroundColor: 'white' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setReportInputHasChanged(true);
                        setActivityDashboardRegion('');
                      }}
                    >
                      Clear
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
            {isReportAccessLoading ? (
              <Box display="flex" justifyContent="center">
                <SpinningSpark
                  isSpinning
                  height="40"
                  width="40"
                  marginTop="0"
                />
              </Box>
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmitReportAccess}
                disabled={!reportInputHasChanged}
              >
                <Typography variant="button" style={{ marginLeft: 10 }}>
                  Submit changes
                </Typography>
              </Button>
            )}
          </Grid>
        </>
      )}
      <Modal
        open={updateSuccessOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CheckCircle style={{ color: 'green', fontSize: '5rem' }} />
          <Typography
            variant="h6"
            component="h2"
            style={{ marginTop: '1rem', marginBottom: '2rem' }}
          >
            User updated successfully
          </Typography>
          <Button
            style={{ backgroundColor: 'green', color: 'white' }}
            onClick={handleClose}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
}

UserPermissions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string,
    win: PropTypes.string,
    platform_id: PropTypes.string.isRequired,
    authorization: PropTypes.string.isRequired,
    login_info: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        os: PropTypes.string.isRequired,
        os_version: PropTypes.string.isRequired,
        mft_version: PropTypes.string.isRequired,
      })
    ).isRequired,
    activity_dashboard_access: PropTypes.string,
  }).isRequired,
  getUser: PropTypes.func.isRequired,
};
