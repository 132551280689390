/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import config from '../../config';
import ObjectUtils from '../../shared/utils/object.utils';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';

const { DEFAULT_LANGUAGE } = config;
const { isObjectBlank } = ObjectUtils;
const { required } = FormValidationsUtils;

/*
  If you are here because you want to generalize this behavior (adding/removing rows of inputs), 
  please read the comments in `PriorityGetStartedStepsForm.js` first.
*/

export default function PriorityCallsToActionForm({
  initialState,
  onStateChanged,
  isValidating,
  langKey,
  spacing,
}) {
  const [callsToAction, setCallsToAction] = useState(() => {
    if (initialState) {
      return initialState.map((cta) => {
        const newCta = { ...cta };
        if (cta.link) {
          try {
            const linkParts = cta.link.split('?');
            const path = linkParts[0];
            const queryParams = linkParts[1] || '';

            // Determine if the link is a createpost link with hashtags
            if (path === 'createpost' && queryParams.startsWith('hashtags=')) {
              newCta.type = 'createpost';

              // Remove "hashtags=" and split the remaining part
              const params = queryParams.replace('hashtags=', '').split('&');

              // Convert parameters into hashtags
              const hashtags = params
                .map((param) => `#${decodeURIComponent(param)}`)
                .filter(Boolean);

              newCta.hashtags = hashtags.length > 0 ? hashtags.join(',') : '';
            } else {
              // if not start with 'hashtags=', then it's another type
              newCta.type = 'other';
              newCta.hashtags = '';
            }
          } catch (error) {
            console.error('Error parsing URL:', error);
            newCta.type = '';
            newCta.hashtags = '';
            newCta.link = '';
          }
        } else {
          newCta.type = '';
        }
        return newCta;
      });
    }
    return [PriorityLanguageCopy.initCallToAction()];
  });

  const [validationErrors, setValidationErrors] = useState([]);

  function validate() {
    const validator = required();
    const newErrors = [];

    callsToAction.forEach((cta, index) => {
      if (isObjectBlank(cta)) {
        return;
      }

      const ctaErrors = {};
      if (!validator.isValid(cta.copy)) {
        ctaErrors.copy = validator.helperText;
      }

      // Validate link or hashtags based on the selected type
      if (cta.type === 'createpost') {
        if (!validator.isValid(cta.hashtags)) {
          ctaErrors.hashtags = validator.helperText;
        }
      } else if (!validator.isValid(cta.link)) {
        ctaErrors.link = validator.helperText;
      }

      if (!_.isEmpty(ctaErrors)) {
        newErrors[index] = ctaErrors;
      }
    });

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(callsToAction, validate());
  }, [callsToAction, isValidating]);

  function appendEmptyItem() {
    setCallsToAction([
      ...callsToAction,
      PriorityLanguageCopy.initCallToAction(),
    ]);
  }

  function generateLink(hashtags) {
    if (!hashtags) return '';

    const trimmedHashtags = hashtags
      .split(',')
      .map((tag) => tag.trim().replace(/^#+/, ''))
      .filter((tag) => tag);

    if (trimmedHashtags.length === 0) return '';

    const encodedHashtags = trimmedHashtags.map(encodeURIComponent).join('&');

    return `createpost?hashtags=${encodedHashtags}`;
  }

  function updateItemAt(index, keyToUpdate, newValue) {
    const newCallsToAction = [...callsToAction];

    if (keyToUpdate === 'hashtags') {
      // Sanitize hashtags input
      const sanitizedValue = newValue.replace(/[^a-zA-Z0-9,#]/g, ''); // Allow only alphanumeric, commas and hashtags
      newCallsToAction[index][keyToUpdate] = sanitizedValue;
      newCallsToAction[index].link = generateLink(sanitizedValue);
    } else {
      newCallsToAction[index][keyToUpdate] = newValue;
    }

    setCallsToAction(newCallsToAction);
  }

  function removeItemAt(index) {
    const newCallsToAction = [...callsToAction];
    newCallsToAction.splice(index, 1);
    setCallsToAction(newCallsToAction);
  }

  const handleTypeChange = (index, event) => {
    const newCallsToAction = [...callsToAction];
    const newType = event.target.value;

    newCallsToAction[index].type = newType;
    newCallsToAction[index].link = '';
    newCallsToAction[index].hashtags = '';

    setCallsToAction(newCallsToAction);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Calls to Action ({langKey})</Typography>
      </Grid>

      {callsToAction.map((cta, index) => (
        <Grid key={`getStarted_${index}`} item container spacing={spacing}>
          <Grid item xs>
            <FormControl fullWidth>
              <InputLabel id={`type-label-${index}`}>Type</InputLabel>
              <Select
                labelId={`type-label-${index}`}
                id={`type-${index}`}
                value={cta.type || ''}
                label="Type"
                onChange={(e) => handleTypeChange(index, e)}
              >
                <MenuItem value="">Select a Type</MenuItem>
                <MenuItem value="createpost">
                  Create Post with hashtags
                </MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <TextField
              label="Text"
              value={cta.copy}
              onChange={(e) => updateItemAt(index, 'copy', e.target.value)}
              fullWidth
              error={_.has(validationErrors[index], 'copy')}
              helperText={_.get(validationErrors[index], 'copy')}
              disabled={!cta.type}
            />
          </Grid>
          {cta.type === 'createpost' && (
            <Grid item xs>
              <TextField
                label="Hashtags (comma separated)"
                value={cta.hashtags || ''}
                onChange={(e) =>
                  updateItemAt(index, 'hashtags', e.target.value)
                }
                fullWidth
                error={_.has(validationErrors[index], 'hashtags')}
                helperText={_.get(validationErrors[index], 'hashtags')}
              />
            </Grid>
          )}
          {cta.type && cta.type !== 'createpost' && (
            <Grid item xs>
              <TextField
                label="In-App or External Link"
                value={cta.link || ''}
                onChange={(e) => updateItemAt(index, 'link', e.target.value)}
                fullWidth
                error={_.has(validationErrors[index], 'link')}
                helperText={_.get(validationErrors[index], 'link')}
              />
            </Grid>
          )}

          <Grid item xs="auto" display="flex" alignItems="center">
            <IconButton
              type="button"
              variant="text"
              color="error"
              size="small"
              onClick={() => removeItemAt(index)}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          type="button"
          variant="text"
          color="success"
          startIcon={<Add />}
          size="small"
          onClick={() => appendEmptyItem()}
        >
          add {callsToAction.length ? 'another' : 'a'} call to action
        </Button>
      </Grid>
    </>
  );
}

PriorityCallsToActionForm.propTypes = {
  onStateChanged: PropTypes.func.isRequired,
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  isValidating: PropTypes.bool,
  langKey: PropTypes.string,
  spacing: PropTypes.number,
};

PriorityCallsToActionForm.defaultProps = {
  initialState: null,
  isValidating: false,
  langKey: DEFAULT_LANGUAGE,
  spacing: 1,
};
