import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import moment from 'moment-timezone';

import {
  Stack,
  Card,
  Typography,
  CardMedia,
  CardActions,
  Button,
  Grid,
  CardHeader,
  TextField,
  IconButton,
  Box,
} from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';

import LoggingService from '../shared/Logging.api';
import { AlertsContext } from '../shared/alerts/Alerts.context';
import styleColors from '../colors.styles';
import UploadButton from '../shared/components/UploadButton';
import StickersService from './Stickers.service';
import StickerCategoryActions from './StickerCategoryActions';
import StickersCategoryReactivateModal from './StickerCategoryReactivateModal';
import SortableCategory from './SortableCategory';
import SortableList from './SortableList';
import SortableItem from './SortableItem';

const StickerCategoryList = ({
  allCategories,
  activeCategories,
  refetch,
  stickers,
  endStickers,
}) => {
  const [updatedCategories, setUpdatedCategories] = useState(allCategories);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [menuExpandedId, setMenuExpandedId] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', image: '' });
  const [error, setError] = useState([]);
  const { showAlert } = useContext(AlertsContext);
  const [modalOpen, setModalOpen] = useState(null);

  const [sorting, setSorting] = useState(false);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [savingSort, setSavingSort] = useState(false);

  useEffect(() => {
    setUpdatedCategories(allCategories);
  }, [allCategories]);

  useEffect(() => {
    setSortedCategories(activeCategories);
  }, [activeCategories]);

  useEffect(() => {
    setMenuExpandedId(null);
  }, [updatedCategories, editingCategory, newCategory, showCategoryForm]);

  const styles = {
    category: {
      padding: '10px',
      margin: '10px',
      overflow: 'visible',
    },
    active: {
      borderRadius: '3px',
    },
    inactive: {
      backgroundColor: styleColors.base.inactive,
      borderRadius: '3px',
    },
    grid: {
      margin: 'auto',
      height: '100%',
    },
    cardMedia: {
      width: '50px',
      height: '50px',
      backgroundSize: 'contain',
      margin: '0 auto',
    },
    title: { margin: 'auto', textAlign: 'left' },
    uploadButton: { alignSelf: 'center', height: '50px', marginTop: '25px' },
    editButton: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      right: -10,
      color: 'white',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'end',
      margin: 0,
      padding: 0,
    },
    editMediaWrapper: { position: 'relative', display: 'flex', height: '100%' },
    visuallyHidden: {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    },
    sortingButton: {
      backgroundColor: savingSort
        ? styleColors.base.light_gray
        : styleColors.base.walmart_blue,
      color: 'white',
    },
  };

  const handleFileUpload = async (event, status) => {
    const image = event.target.files[0];
    try {
      const fileURL = await StickersService.uploadStickerFile(image);
      handleChange('image', fileURL, status);
    } catch (err) {
      LoggingService.error('Error uploading file', err);
      showAlert('Error uploading file', 'error');
    }
  };

  const handleSubmit = async (category) => {
    const errors = StickersService.validateCategorySubmit(category);
    setError(errors);
    if (errors.length > 0) {
      return;
    }

    if (category?.id && _.find(allCategories, ['id', category.id])) {
      try {
        await StickersService.updateCategories([category]);
      } catch (err) {
        LoggingService.error('Error updating category', err);
        showAlert('Error updating category', 'error');
        return;
      }
    } else {
      try {
        const newOrderCategories = activeCategories.map((item) => {
          return { ...item, order_index: item.order_index + 1 };
        });
        await StickersService.createCategory({
          ...category,
          order_index: 1,
        });
        await StickersService.updateCategories([...newOrderCategories]);
      } catch (err) {
        LoggingService.error('Error creating category', err);
        showAlert('Error creating category', 'error');
        return;
      }
    }
    setEditingCategory(null);
    setNewCategory(null);
    setShowCategoryForm(false);
    refetch();
  };

  // wrapped function to prevent user from hitting submit multiple times
  // and getting duplicate categories created
  const debouncedSubmit = _.debounce(handleSubmit, 1000);

  function handleChange(name, value, status) {
    if (status === 'new') {
      setNewCategory({
        ...newCategory,
        [name]: value,
      });
    } else {
      setEditingCategory({
        ...editingCategory,
        [name]: value,
      });
    }
  }

  const cancel = () => {
    if (editingCategory) {
      setEditingCategory(null);
      setError([]);
    } else {
      setNewCategory(null);
      setShowCategoryForm(false);
      setError([]);
    }

    setMenuExpandedId(null);
  };

  const removeCategory = async (category, isPermanent = false) => {
    const options = isPermanent
      ? {
          deleted_at: moment().format(),
        }
      : {
          enabled: 0,
        };

    // Reassign order_index of active categories to account for removal
    const orderedActiveCategories = _.orderBy(
      _.filter(
        activeCategories,
        (tempCategory) => tempCategory.id !== category.id
      ),
      ['order_index'],
      ['desc']
    );
    const newOrderCategories = orderedActiveCategories.map((item, index) => ({
      ...item,
      order_index: orderedActiveCategories.length - index,
    }));
    try {
      await StickersService.updateCategories([
        {
          ...category,
          ...options,
        },
        ...newOrderCategories,
      ]);
    } catch (err) {
      LoggingService.error('Error deleting category', err);
      showAlert('Error deleting category', 'error');
    }

    refetch();
    setMenuExpandedId(null);
  };

  const handleSaveSorting = () => {
    setSavingSort(true);
    const updatedSortedCategories = sortedCategories.map((category, index) => ({
      ...category,
      order_index: sortedCategories.length - index,
    }));

    StickersService.updateCategories(updatedSortedCategories)
      .catch((err) => {
        LoggingService.error('Error updating categories', err);
        showAlert('Error updating categories', 'error');
      })
      .finally(() => {
        setSavingSort(false);
        setSorting(false);
        refetch();
      });
  };

  const handleNewOrEditState = (status, category = {}) => {
    if (status === 'new') {
      setEditingCategory(null);
      setShowCategoryForm(true);
    }
    if (status === 'editing' && category) {
      setEditingCategory(category);
      setNewCategory(null);
      setShowCategoryForm(false);
    }
  };

  const renderAddCategory = (categoryID) => {
    let category;
    let status = 'published';

    if (editingCategory?.id === categoryID) {
      status = 'editing';
    } else if (!_.find(allCategories, ['id', categoryID])) {
      status = 'new';
    }

    if (categoryID) {
      category = _.find(updatedCategories, ['id', categoryID]);
    } else {
      // assume new category
      category = newCategory;
    }

    return (
      <>
        {status === 'editing' || status === 'new' ? (
          <Card
            style={{
              ...styles.category,
              backgroundColor: styleColors.base.very_light_blue,
            }}
            key={categoryID}
          >
            <Grid
              container
              style={{ display: 'grid', gridTemplateColumns: '1fr 5fr 2fr' }}
            >
              <Grid item size={2}>
                {category?.image ? (
                  <div style={styles.editMediaWrapper}>
                    <CardMedia
                      image={
                        status === 'new'
                          ? category?.image
                          : editingCategory?.image
                      }
                      title={editingCategory?.name}
                      style={styles.cardMedia}
                    />
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<EditIcon />}
                      style={styles.editButton}
                    >
                      <input
                        type="file"
                        accept=".png"
                        onChange={(event) => handleFileUpload(event, status)}
                        style={styles.visuallyHidden}
                      />
                    </Button>
                  </div>
                ) : (
                  <UploadButton
                    handleFileUpload={(params) =>
                      handleFileUpload(params, status)
                    }
                    accept=".png"
                    style={{ display: 'flex', margin: '0' }}
                    buttonStyle={styles.uploadButton}
                  />
                )}
              </Grid>
              <Grid
                item
                style={{ ...styles.grid, width: '80%', display: 'flex' }}
              >
                <TextField
                  label="Category name"
                  variant="filled"
                  placeholder="Enter category name"
                  onChange={(event) =>
                    handleChange('name', event.target.value, status)
                  }
                  defaultValue={
                    status === 'editing' ? editingCategory.name : ''
                  }
                  fullWidth
                  required
                  style={{ alignSelf: 'center' }}
                />
              </Grid>
              <Grid item style={{ ...styles.grid, display: 'flex' }}>
                <div style={{ alignSelf: 'end', width: '100%' }}>
                  <Button
                    style={{
                      backgroundColor: styleColors.base.walmart_blue,
                      height: '40px',
                      color: 'white',
                      width: '100%',
                    }}
                    onClick={() =>
                      debouncedSubmit(
                        status === 'editing' ? editingCategory : newCategory
                      )
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ height: '25%', fontSize: '60%', width: '100%' }}
                    onClick={() => cancel()}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Typography>{error.length > 0 && `${error[0]}`}</Typography>
          </Card>
        ) : (
          <>
            <div style={{ position: 'relative' }}>
              {menuExpandedId === categoryID && (
                <ClickAwayListener onClickAway={() => setMenuExpandedId(null)}>
                  <StickerCategoryActions
                    onEdit={() => handleNewOrEditState('editing', category)}
                    onArchive={() => removeCategory(category, false)}
                    onDelete={() => removeCategory(category, true)}
                    categoryName={category.name}
                  />
                </ClickAwayListener>
              )}
            </div>
            <Card
              style={{
                ...styles.category,
                ...(_.find(activeCategories, ['id', category.id])
                  ? styles.active
                  : styles.inactive),
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
                flexDirection="row"
              >
                <Box display="flex" gap={2}>
                  <CardMedia
                    image={category.image}
                    title={category.name}
                    style={styles.cardMedia}
                  />
                  <Typography style={styles.title}>{category.name}</Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  justifyContent="flex-end"
                >
                  {!_.find(activeCategories, ['id', category.id]) ? (
                    <CardActions>
                      <Button
                        onClick={() => {
                          setModalOpen(category.id);
                        }}
                      >
                        Reactivate
                      </Button>
                      {modalOpen === category.id && (
                        <StickersCategoryReactivateModal
                          category={category}
                          activeCategories={activeCategories}
                          closeModal={() => {
                            setModalOpen(null);
                          }}
                          categoryStickers={_.filter(
                            stickers,
                            (sticker) => sticker.category_id === category.id
                          )}
                          refetch={refetch}
                          endStickers={endStickers}
                        />
                      )}
                    </CardActions>
                  ) : (
                    <Typography style={{ textAlign: 'right' }}>
                      Created: {moment(category.createdAt).format('MM/DD/YY')}
                    </Typography>
                  )}
                  {_.find(activeCategories, ['id', category.id]) && (
                    <IconButton
                      onClick={() =>
                        menuExpandedId
                          ? setMenuExpandedId(null)
                          : setMenuExpandedId(categoryID)
                      }
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Card>
          </>
        )}
      </>
    );
  };

  const renderReorderControls = () => {
    return (
      <>
        {sorting ? (
          <>
            <Button
              onClick={() => {
                setSortedCategories(activeCategories);
                setSorting(false);
              }}
            >
              Cancel
            </Button>
            <Button style={styles.sortingButton} onClick={handleSaveSorting}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Button
              style={styles.sortingButton}
              onClick={() => setSorting(true)}
            >
              Reorder
            </Button>
            <IconButton
              onClick={() => handleNewOrEditState('new')}
              style={styles.menuButton}
            >
              <AddIcon />
            </IconButton>
          </>
        )}
      </>
    );
  };

  const renderCategory = (item) => {
    return <SortableCategory category={item} />;
  };

  const renderSortableCategories = (categories) => {
    return (
      <>
        {categories.map((item) => (
          <SortableItem
            key={item.order_index}
            itemId={item.id}
            renderFunc={renderCategory(item)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <Stack spacing={1}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <CardHeader title="Categories" />
          <Box
            display="flex"
            marginRight={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="right"
            gap={1}
          >
            {renderReorderControls()}
          </Box>
        </Box>
        <>
          {sorting ? (
            <>
              <p style={{ margin: '0 auto' }}>
                The changes you make here will be reflected in the MLS app upon
                clicking &quot;Save&quot;.
              </p>
              <SortableList
                items={sortedCategories}
                setItems={setSortedCategories}
                renderFunc={renderSortableCategories(sortedCategories)}
              />
            </>
          ) : (
            <>
              {showCategoryForm && renderAddCategory(null)}
              {_.orderBy(
                updatedCategories,
                ['enabled', 'order_index', 'createdAt'],
                ['desc', 'desc', 'desc']
              ).map((category) => {
                return (
                  <div key={category.id}>{renderAddCategory(category.id)}</div>
                );
              })}
            </>
          )}
        </>
      </Stack>
    </>
  );
};

export default StickerCategoryList;

StickerCategoryList.propTypes = {
  allCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetch: PropTypes.func.isRequired,
  stickers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  endStickers: PropTypes.func.isRequired,
};
