import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import styleColors from '../colors.styles';

const StickerCategoryActions = forwardRef((props, ref) => {
  const confirm = useConfirm();

  const styles = {
    menuList: {
      position: 'absolute',
      index: 150,
      top: 60,
      right: 10,
      backgroundColor: styleColors.base.almost_white_blue,
      borderRadius: '3px',
      zIndex: 150,
    },
  };

  const { onEdit, onArchive, onDelete, categoryName } = props;

  return (
    <List ref={ref} style={styles.menuList}>
      <ListItemButton onClick={onEdit}>
        <ListItemText primary="Edit Category" />
      </ListItemButton>
      <ListItemButton onClick={onArchive}>
        <ListItemText primary="Archive Category" />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          confirm({
            title: `Are you sure you want to delete the category "${categoryName}"?`,
            description:
              'This will permanently remove the category from future use, along with all stickers in it. Records of this category will be retained for reporting reasons.',
            confirmationText: 'Yes',
            cancellationText: 'No',
          })
            .then(() => onDelete())
            .catch(() => {
              /* do nothing on cancel */
            });
        }}
      >
        <ListItemText primary="Permanently Delete Category" />
      </ListItemButton>
    </List>
  );
});

export default StickerCategoryActions;

StickerCategoryActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
};
