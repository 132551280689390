import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import {
  Typography,
  Stack,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@mui/lab/LoadingButton';
import _ from 'lodash';
import styleColors from '../colors.styles';

const StickersCreateForm = ({
  categories,
  handleInputChange,
  handleSubmit,
  selectedCategory,
  error,
  submitLoading,
  startDate,
  selectedStickers,
  forceEvergreen,
}) => {
  const [evergreen, setEvergreen] = useState(forceEvergreen || false);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (selectedStickers.length === 1) {
      const sticker = selectedStickers[0];
      if (sticker.end_date === null) {
        setEvergreen('now');
        handleInputChange('endDate', 'now');
      } else {
        setEndDate(moment.tz(sticker.end_date, 'America/Chicago'));
      }
    } else if (selectedStickers.length > 1) {
      const uniqueEndDates = _.uniq(_.map(selectedStickers, 'end_date'));
      if (uniqueEndDates.length === 1) {
        if (uniqueEndDates[0] !== null) {
          setEvergreen(null);
          setEndDate(moment.tz(uniqueEndDates[0], 'America/Chicago'));
        } else {
          setEvergreen('now');
          handleInputChange('endDate', 'now');
        }
      } else {
        setEvergreen(null);
        setEndDate(null);
      }
      // Clear dates for multi-sticker editing to maintain original values
    }
  }, [selectedStickers]);

  const styles = {
    submitButton: {
      margin: '20px auto 0px auto',
      display: 'block',
      marginTop: '20px',
    },
    buttonActive: {
      backgroundColor: styleColors.base.walmart_blue,
      color: 'white',
    },
    buttonInactive: {
      backgroundColor: styleColors.base.medium_gray,
      color: 'white',
    },
    evergreenUnselected: {
      backgroundColor: styleColors.base.almost_white_blue,
      border: `1px solid ${styleColors.base.almost_white_blue}`,
      '&:hover': {
        backgroundColor: styleColors.base.light_green,
        border: `1px solid ${styleColors.base.green}`,
      },
    },
    evergreenSelected: {
      backgroundColor: styleColors.base.green,
      border: `1px solid ${styleColors.base.green}`,
      '&:hover': {
        backgroundColor: styleColors.base.green,
        border: `1px solid ${styleColors.base.green}`,
      },
    },
  };

  // evergreen needs to be handled differently 'cause it affects styling
  const handleEvergreen = () => {
    // toggle value, save it to state, and send it up to parent component
    const newEvergreenValue = !evergreen;
    setEvergreen(newEvergreenValue);
    if (selectedStickers.length > 1) {
      // For multi-sticker editing, applies 'now' to all or reset
      handleInputChange('endDate', newEvergreenValue ? 'now' : null);
    } else {
      handleInputChange('endDate', newEvergreenValue ? 'now' : endDate);
    }
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      style={{ width: '60%', margin: '0 auto' }}
    >
      <DatePicker
        label="Start at midnight Central on"
        variant="filled"
        fullWidth
        required
        value={startDate}
        onChange={(event) => {
          const centralDate = moment
            .tz(event.format('YYYY-MM-DD'), 'America/Chicago')
            .startOf('day');
          handleInputChange('startDate', centralDate);
        }}
      />
      {forceEvergreen ? (
        <Stack direction="column" spacing={2}>
          <Typography style={{ textAlign: 'left', fontSize: '80%' }}>
            End date:
          </Typography>
          <Button
            sx={{
              border: `2px solid ${styleColors.base.light_gray}`,
              backgroundColor: styleColors.base.light_gray,
              borderRadius: '10px',
              color: 'white',
              maxWidth: '150px',
            }}
            disabled
          >
            Evergreen
          </Button>
          <Typography
            style={{
              textAlign: 'left',
              fontSize: '80%',
              color: styleColors.base.dark_gray,
            }}
          >
            All reactivated stickers will be evergreen. To add end dates, please
            reactivate the category, and then edit the stickers normally.
          </Typography>
        </Stack>
      ) : (
        <Stack direction="row" style={{ display: 'flex' }} spacing={4}>
          <DatePicker
            label="End at 11:59 Central on"
            variant="filled"
            fullWidth
            disabled={evergreen}
            value={endDate}
            onChange={(event) => {
              handleInputChange('endDate', event);
              // hold on to end date in case evergreen is toggled off and this needs restored
              setEndDate(event);
            }}
          />
          <Typography style={{ alignSelf: 'center' }}> OR </Typography>
          <Button
            sx={
              evergreen ? styles.evergreenSelected : styles.evergreenUnselected
            }
            onClick={() => handleEvergreen()}
          >
            Evergreen
          </Button>
        </Stack>
      )}

      <FormControl variant="filled" fullWidth>
        <InputLabel id="category-select-label">Category</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          label="Category"
          variant="filled"
          value={selectedCategory}
          fullWidth
          required
          disabled={categories.length <= 1}
        >
          {categories.map((category) => (
            <MenuItem
              key={category.id}
              value={category.name}
              onClick={() => handleInputChange('category', category.name)}
            >
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingButton
        onClick={() => handleSubmit()}
        style={
          error.length > 0
            ? { ...styles.submitButton, ...styles.buttonInactive }
            : { ...styles.submitButton, ...styles.buttonActive }
        }
        disabled={!!(error.length > 0) || submitLoading}
        loading={submitLoading}
      >
        <Typography>Submit</Typography>
      </LoadingButton>
    </Stack>
  );
};

export default StickersCreateForm;
StickersCreateForm.defaultProps = {
  selectedStickers: [],
  startDate: null,
  forceEvergreen: false,
};

StickersCreateForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  startDate: PropTypes.shape({}),
  selectedStickers: PropTypes.arrayOf(
    PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    })
  ),
  forceEvergreen: PropTypes.bool,
};
