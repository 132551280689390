import React from 'react';
import _ from 'lodash';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  CardActionArea,
  CardMedia,
  Stack,
  Box,
} from '@mui/material';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styleColors from '../colors.styles';

const StickersDetail = ({
  selectedStickers,
  setSelectedStickers,
  smallScreen,
  endStickers,
  setModalOpen,
}) => {
  const styles = {
    card: {
      backgroundColor: styleColors.base.barely_blue,
      minHeight: smallScreen ? '30vh' : '50vh',
    },
    endButton: {
      color: 'white',
      backgroundColor: styleColors.base.dark_red,
    },
    editButton: {
      color: 'white',
      backgroundColor: styleColors.base.walmart_blue,
    },
    button: {
      color: 'white',
      backgroundColor: styleColors.base.walmart_blue,
    },
    cardMedia: {
      width: '15vw',
      height: '15vw',
      maxHeight: '200px',
      maxWidth: '200px',
      backgroundSize: 'contain',
      margin: '0 auto',
      backgroundOrigin: 'content-box',
      backgroundColor: 'black',
      padding: '1em',
      borderRadius: '5px',
    },
    cardContent: smallScreen ? { width: '30%' } : { margin: '0 auto' },
    previewMedia: {
      position: 'absolute',
      opacity: 0.9,
      boxShadow: '-3px -3px 5px white',
    },
    moreText: { display: 'block', textAlign: 'right', marginTop: '240px' },
  };

  const getStickerDetailSection = () => {
    // If multiple stickers are selected, show a preview of the last (up to) 3 stickers
    if (selectedStickers.length > 1) {
      const preview = _.takeRight(selectedStickers, 3);
      return (
        <>
          <CardContent>
            <Typography variant="h6">Multiple stickers selected</Typography>
            <Typography>
              Select one at a time to view sticker details.
            </Typography>
          </CardContent>
          <CardContent style={{ position: 'relative', minHeight: '20vw' }}>
            {_.map(preview, (sticker, index) => {
              const offset = index + 1;
              return (
                <CardMedia
                  key={sticker.id}
                  image={sticker.image}
                  title={sticker.name}
                  style={{
                    ...styles.cardMedia,
                    ...styles.previewMedia,
                    zIndex: offset,
                    left: offset * 50,
                    marginTop: offset * 10,
                  }}
                />
              );
            })}
            {selectedStickers.length > 3 && (
              <Typography style={styles.moreText}>
                + {selectedStickers.length - preview.length} more
              </Typography>
            )}
          </CardContent>
          <CardActions style={{}}>
            <Stack direction="row" spacing={12} style={{ margin: '0 auto' }}>
              <Button
                style={styles.button}
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                Edit All
              </Button>
              <Button
                style={styles.button}
                onClick={() => {
                  setSelectedStickers([]);
                }}
              >
                Deselect All
              </Button>
              <Button
                style={styles.endButton}
                onClick={() => {
                  endStickers(selectedStickers);
                }}
              >
                End All Now
              </Button>
            </Stack>
          </CardActions>
        </>
      );
    }

    // If a single sticker is selected, show its details
    if (selectedStickers.length === 1) {
      const start_date = moment(selectedStickers[0].start_date)
        .tz('America/Chicago')
        .format('MM/DD/YY');
      const end_date =
        selectedStickers[0].end_date === null
          ? 'Evergreen'
          : moment(selectedStickers[0].end_date)
              .tz('America/Chicago')
              .format('MM/DD/YY');
      return (
        <Stack direction={{ sm: 'row', md: 'column' }}>
          <CardActionArea
            href={selectedStickers[0].image}
            target="_blank"
            style={{ width: smallScreen && '30%' }}
          >
            <CardMedia
              image={selectedStickers[0].image}
              title={selectedStickers[0].name}
              style={styles.cardMedia}
            />
          </CardActionArea>
          <CardContent style={styles.cardContent}>
            <Typography variant="subtitle1">
              {`"`}
              {selectedStickers[0].name}
              {`"`}
            </Typography>
            <Typography variant="subtitle2">
              Category: {selectedStickers[0].category}
            </Typography>
            <Typography variant="subtitle2">
              Created By: {_.startCase(selectedStickers[0].created_by)}
            </Typography>
            <Typography variant="subtitle2">
              Start Date: {start_date}
            </Typography>
            <Typography variant="subtitle2">End Date: {end_date}</Typography>
          </CardContent>
          <CardActions>
            {
              // TODO: reinstate commented sections if/when reactivation is implemented

              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  style={{ ...styles.editButton, margin: '0 auto' }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  style={{ ...styles.endButton, margin: '0 auto' }}
                  onClick={() => {
                    endStickers(selectedStickers);
                  }}
                >
                  End Now
                </Button>
              </Box>

              // ) : (
              //   <Button
              //     style={{ ...styles.button, margin: '0 auto' }}
              //     onClick={() => {
              //       // eslint-disable-next-line no-alert
              //       alert('Not implemented yet');
              //     }}
              //   >
              //     Reactivate
              //   </Button>
              // )
            }
          </CardActions>
        </Stack>
      );
    }

    // if no stickers are selected, show a message
    return (
      <CardContent>
        <Typography>Select a sticker to view its details</Typography>
      </CardContent>
    );
  };
  return (
    <Card style={styles.card}>
      <CardHeader title="Sticker details" />
      {getStickerDetailSection()}
    </Card>
  );
};

export default StickersDetail;

StickersDetail.propTypes = {
  selectedStickers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string,
      created_by: PropTypes.string.isRequired,
    })
  ).isRequired,
  setSelectedStickers: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool,
  endStickers: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
};

StickersDetail.defaultProps = {
  smallScreen: false,
};
