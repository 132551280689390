import AppService from '../shared/App.api';

export default {
  uploadCSVData,
  getSocialChamps,
  deleteSocialChamps,
};

const socialChampsUrl = '/admin/social-champs';

async function uploadCSVData(listOfSocialChamps) {
  try {
    const response = await AppService.post(
      `${socialChampsUrl}/upload`,
      listOfSocialChamps
    );

    if (!response.status === 200) {
      throw new Error('Failed to upload the data');
    }
    return response.data;
  } catch (err) {
    throw new Error('Error uploading data');
  }
}

async function getSocialChamps() {
  try {
    const response = await AppService.get(socialChampsUrl);
    return response.data;
  } catch (error) {
    throw new Error('Failed to get social champs');
  }
}

async function deleteSocialChamps(socialChamps) {
  try {
    const response = await AppService.patch(
      `${socialChampsUrl}/delete`,
      socialChamps
    );
    return response.data;
  } catch (error) {
    throw new Error('Failed to get social champs');
  }
}
