import AppApi from '../shared/App.api';

export default { getPowerBICredentials };

function getPowerBICredentials(reportName) {
  return AppApi.get('/admin/powerbi-credentials', {
    params: {
      report: reportName,
    },
  });
}
