import socialChampsApi from './SocialChamps.api';

export default {
  parseCSV,
  handleFileUpload,
};

function parseCSV(csvContent) {
  const rows = csvContent.split('\n').filter((row) => row.trim() !== '');
  const headers = rows[0].split(',');

  const data = rows.slice(1).map((row) => {
    const values = row.split(',');
    return headers.reduce((accumulator, header, index) => {
      const trimmedHeader = header.trim().toLowerCase();
      const trimmedValue = values[index]?.trim().toLowerCase();
      const value =
        trimmedHeader === 'store' ? Number(trimmedValue) : trimmedValue;

      return {
        ...accumulator,
        [trimmedHeader]: value,
      };
    }, {});
  });
  return data;
}

function validateCSV(data) {
  // validate data exists, is an array, and has at least one element
  if (!data || !Array.isArray(data) || data.length === 0) {
    return 'No data found in the CSV file.';
  }

  const requiredHeaders = ['name', 'email', 'store'];
  const headers = Object.keys(data[0]);
  const missingHeaders = requiredHeaders.filter(
    (header) => !headers.includes(header)
  );
  if (missingHeaders.length > 0) {
    return `Document is missing the following headers: ${missingHeaders.join(
      ', '
    )}.`;
  }

  // validate that values are present
  const dataValues = data.slice(1);
  const missingValues = dataValues.reduce((accumulator, row, index) => {
    Object.values(row).forEach((value) => {
      if (!value) {
        accumulator.push(index + 3);
      }
    });
    return accumulator;
  }, []);
  if (missingValues.length > 0) {
    const pluralize = (word, count, pluralForm = `${word}s`) =>
      count === 1 ? word : pluralForm;

    const row = pluralize('Row', missingValues.length);
    const verb = pluralize('is', missingValues.length, 'are');

    return `${row} ${missingValues.join(', ')} ${verb} missing a value.`;
  }

  return null;
}

function handleFileUpload(event, setJsonResult, setErrors) {
  setErrors(null);
  const file = event.target.files[0];
  if (!file) {
    setErrors('No file selected.');
    return;
  }

  const reader = new FileReader();
  reader.onload = async (e) => {
    const csvContent = e.target.result;
    let uploadedData;
    try {
      const parsedData = parseCSV(csvContent);
      const validationsErrors = validateCSV(parsedData);
      if (validationsErrors) {
        setErrors(validationsErrors);
        return;
      }
      try {
        uploadedData = await socialChampsApi.uploadCSVData(parsedData);
        setJsonResult(uploadedData);
      } catch (err) {
        setErrors('Error uploading data.');
      }
    } catch (err) {
      setErrors('Error parsing CSV file.');
    }
  };
  reader.readAsText(file);
}
