import React from 'react';
import PropTypes from 'prop-types';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';

export default function SortableList({ items, setItems, renderFunc }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Start dragging after a small movement
      },
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setItems((prev) => {
        const onlyIds = prev.map((item) => item.id);

        const oldIndex = onlyIds.indexOf(active.id);
        const newIndex = onlyIds.indexOf(over.id);

        const newArray = arrayMove(prev, oldIndex, newIndex);
        return newArray;
      });
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={rectSortingStrategy}
      >
        {renderFunc}
      </SortableContext>
    </DndContext>
  );
}

SortableList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setItems: PropTypes.func.isRequired,
  renderFunc: PropTypes.shape({}).isRequired,
};
