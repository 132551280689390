const moment = require('moment-timezone');

module.exports = {
  TENANT: 'wmus',
  APP_ENV: 'prod',
  URL_BASE: 'https://mls-wmus.bnservers.com',
  PINGFED_BASE: 'https://pfedprod.wal-mart.com',
  REDIRECT_URL: 'https://mls-wmus-admin.bnservers.com/',
  LOGGLY_TOKEN: '4a7408b2-648f-40f5-ab4b-c72ab8f11b63',
  DEFAULT_LANGUAGE: 'en',
  ALL_LANGUAGES: ['en', 'es'],
  MENU_ITEMS: [
    'Video Review',
    'Users',
    'Social Champs',
    'Stores',
    'Priorities',
    'Stickers',
    'Post of the Day',
    'PowerBI',
  ],
  getWalmartWeek: (date) => {
    const targetDate = moment(date);
    let walmartFirstSaturday = moment(
      `${targetDate.format('YYYY')}-02-02`,
      'YYYY-DD-MM'
    )
      .day(0)
      .isoWeekday(6);
    let dateDiff = targetDate.diff(walmartFirstSaturday, 'days');

    if (dateDiff < 0) {
      walmartFirstSaturday = moment(
        `${targetDate.format('YYYY') - 1}-02-02`,
        'YYYY-DD-MM'
      )
        .day(0)
        .isoWeekday(6);
      dateDiff = targetDate.diff(walmartFirstSaturday, 'days');
    }

    return Math.floor(dateDiff / 7) + 1;
  },
  getWMWeekStartDates: (currentDate) => {
    // this doesn't subtract an even 7 because WMUS/WMCA/SCUS weeks run Sat-Fri
    return {
      previous_week: moment(currentDate)
        .startOf('week')
        .subtract(8, 'days')
        .format(),
      current_week: moment(currentDate)
        .startOf('week')
        .subtract(1, 'day')
        .format(),
      next_week: moment(currentDate).startOf('week').add(6, 'days').format(),
    };
  },
};
